<template>
  <div class="heas">
    <div style="position: relative">
      <div class="flex">
        <div :class="cur_opt == 1 ? 'voies' : ''" class="h_l flex">
          <div class="lodo" @click="logo">
            <img class="lodo" :src="logos" alt="" />
          </div>
          <div class="nav nav5">
            <div class="big">
              <div class="boxq">
                <div @click="logo" class="qwer">HOME</div>
              </div>
              <div class="boxq" v-for="(it, i) in arr1" :key="'arr1-' + i">
                <div @click="about" class="qwer">{{ it.name }}</div>
                <div class="bpo">
                  <div
                    class="qaz"
                    @click="det(item)"
                    v-for="(item, index) in it.item"
                    :key="index"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="boxq" v-for="(it, i) in arr2" :key="'arr2-' + i">
                <div @click="products" class="qwer">{{ it.name }}</div>
                <div class="bpo">
                  <div
                    class="qaz"
                    @click="det_opem(item)"
                    v-for="(item, index) in it.item"
                    :key="index"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div
                class="boxq"
                @click="ope(i)"
                v-for="(it, i) in arr"
                :key="'arr-' + i"
              >
                <div class="qwer">{{ it.name }}</div>
                <div class="bpo">
                  <div
                    class="qaz"
                    @click="det(item)"
                    v-for="(item, index) in it.item"
                    :key="index"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          @click="open_se"
          :class="cur_opt == 1 ? 'voies' : ''"
          class="serch"
          style="text-align: center"
        >
          <i class="el-icon-search"></i>
        </div>
        <div @click="version" class="version flexC">
          <div class="chewa">Chinese version</div>
        </div>
      </div>
      <div v-if="shows" class="searchs flexC">
        <div class="se_ipt flex">
          <el-input
            clear="secrch_ipt"
            v-model="names"
            placeholder="Search"
          ></el-input>
          <div class="se_btn flexC" @click="secrch_name">
            <img class="imgsearch" src="../assets/image/20.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- <side></side> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      shows: false,
      activeIndex: "1",
      cur_opt: 0,
      colorsw: "transparent",
      arr1: [
        {
          name: "ABOUT",
          item: [
            {
              name: "Company profile",
              id: 1,
            },
            {
              name: "Enterprise reality",
              id: 2,
            },
            {
              name: "R&D and Production",
              id: 3,
            },
          ],
        },
      ],
      arr2: [
        {
          name: "PRODUCT",
          item: [],
        },
      ],
      arr: [
        {
          name: "OEM PROCESSING",
        },
        {
          name: "INFORMATION",
          item: [
            {
              name: "Industry dynamics",
              id: 143,
            },
            {
              name: "Xianqi Dynamics",
              id: 127,
            },
          ],
        },
        {
          name: "CONTACT",
        },
      ],
      names: "",
      logos: "",
    };
  },
  created() {
    this.catList();
    this.alogos();
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  methods: {
    version() {
      window.open("http://www.cichbio.com");
    },
    products() {
      this.$router.push({ path: "/product" });
    },
    about() {
      this.$router.push({ path: "/about" });
    },
    async alogos() {
      let res = await this.$http.index.logo({});
      if (res.code == 1) {
        this.logos = this.$imgurl + res.data.logo_img;
      }
    },
    async secrch_name() {
      let res = await this.$http.index.productInfo({
        name: this.names,
      });
      if (res.code == 1) {
        if (res.data != null) {
          this.$router.push({ path: "/pro_det", query: { id: res.data.id } });
        } else {
          this.$message.error("No content available at the moment");
        }
      }
    },
    open_se() {
      this.shows = !this.shows;
    },
    async catList() {
      let res = await this.$http.index.catList();
      if (res.code == 1) {
        this.arr2 = [
          {
            name: "PRODUCT",
            item: res.data,
          },
        ];
      }
    },
    det_opem(it) {
      this.$router.push({ path: "/pro_list", query: { id: it.id } });
    },
    det(it) {
      if (it.id <= 3) {
        this.$router.push({ path: "/about", query: { id: it.id } });
      } else if (it.id == 143) {
        this.$router.push({ path: "/new", query: { id: "143" } });
      } else if (it.id == 127) {
        this.$router.push({ path: "/new", query: { id: "127" } });
      }
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 1) {
        this.cur_opt = 1;
      } else {
        this.cur_opt = 0;
      }
     
    },
    ope(e) {
      if (e == 0) {
        this.$router.push("/oem");
      } else if (e == 1) {
        this.$router.push({ path: "/new", query: { id: "143" } });
      } else if (e == 2) {
        this.$router.push("/callme");
      } else if (e == 3) {
      } else if (e == 4) {
      }
    },
    logo() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-input__inner {
  border: 2px solid #1ba8c9 !important;
  border-radius: 0 !important;
  height: 38px;
}
.searchs {
  width: 1402px;
  height: 92px;
  background: #ffffff;
  border-radius: 0px 0px 50px 50px;
  margin-left: 209px;
  position: absolute;
  .se_ipt {
    width: 552px;
    .se_btn {
      width: 87px;
      height: 38px;
      background: #1ba8c9;
      .imgsearch {
        width: 23px;
        height: 23px;
      }
    }
  }
}
.items:hover .oews {
  display: block;
}
.items {
  margin-right: 92px;
  cursor: pointer;
  position: relative;
  .oews {
    display: none;
    position: absolute;
    padding-top: 20px;
    background: #fff;
    width: 300px;
    margin-left: -100px;
    text-align: center;
    .wpu {
      padding: 13px 0;
    }
  }
  .names {
    font-family: Source Sans Variable;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
  }
}

.chewa {
  font-family: Source Sans Variable;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}

.voies {
  background: #1ba8c9 !important;
}
.version {
  cursor: pointer;
  width: 173px;
  height: 79px;
  border-radius: 10px;
  margin-left: 20px;
  font-family: "Variable";
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  background: #1ba8c9;
  text-align: center;
}
.el-icon-search {
  font-size: 30px;
  color: #fff;
  line-height: 81px;
}
.serch {
  width: 92px;
  height: 79px;
  background-color: rgba(77, 77, 77, 0.31);
  border-radius: 10px;
  margin-left: 51px;
}
.rt-t {
  color: #000 !important;
}

.heas {
  position: sticky;
  padding-right: 142px;
  z-index: 100;
  margin-top: 80px;
  // padding-top: 80px;
  top: 00px;
  // height: 0px;
  .h_l {
    background-color: rgba(77, 77, 77, 0.31);
    // padding-left: 174px;
    padding: 0px 0px 0px 144px;
    border-radius: 0px 10px 10px 0;
    height: 80px;

    .lodo {
      font-family: Source Sans Variable;
      font-weight: bold;
      font-size: 33px;
      color: #ffffff;
      margin-right: 150px;
      cursor: pointer;
      width: 80px;
    }
  }
}

/* All */

.nav ul {
  *zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
// .nav ul:before,
// .nav ul:after {
//   content: "";
//   display: table;
// }
// .nav ul:after {
//   clear: both;
// }
.qwer {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-family: "Variable";
  height: 80px;
  line-height: 80px;
}

.boxq {
  // float: left;
  position: relative;
}
// .cuye {
//   float: left;
//   position: relative;
// }

.boxq {
  margin-right: 79px;
}
.bpo {
  background: rgba(77, 77, 77, 0.31);
}

.nav .qwer:hover {
  // text-decoration: none;
  // background: #595959;
  transition: all 0.7s ease 0s;
}

// .cuye:hover {
//   background-color: rgba(148, 235, 253, 0.7);
//   color: #000;
//   font-weight: bold;
// }
.big {
  display: flex;
}

/* Fold Out
============================== */
.big > .boxq:hover .bpo {
  max-height: 400px;
  transition: all 0.7s ease;
  // -webkit-transform: perspective(400) rotate3d(0, 0, 0, 0);
}
.qaz {
  padding: 15px 0px;
  text-align: center;
  font-size: 20px;
  font-family: "Variable";
  color: #fff !important;
}
.bpo .qaz:hover {
  background: #1ba8c9 !important;
  color: #fff !important;
}
.nav5 .bpo {
  cursor: pointer;
  position: absolute;
  margin-left: -70px;
  top: 80px;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  // -webkit-transform: perspective(400) rotate3d(1, 0, 0, -90deg);
  // -webkit-transform-origin: 50% 0;
  // -webkit-transition: 750ms;
  // -moz-transition: 750ms;
  // -o-transition: 750ms;
  // transition: 750ms;
  transition: all 0.3s ease;
  width: 250px;
}
</style>
