var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"lists"},[_vm._m(0),_c('div',{staticClass:"num"},[_c('a',{attrs:{"href":'tel:' + _vm.info.telephone,"target":"_blank"}},[_vm._v(_vm._s(_vm.info.telephone))])])]),_c('div',{staticClass:"lists"},[_vm._m(1),_c('div',{staticClass:"num"},[_c('a',{attrs:{"href":'https://wa.me/' + _vm.info.mobile,"target":"_blank"}},[_vm._v(_vm._s(_vm.info.mobile))])])]),_c('div',{staticClass:"lists",on:{"click":function($event){_vm.dialogFormVisible = true}}},[_vm._m(2)]),_c('el-backtop',[_c('div',{staticClass:"tit"},[_c('div',{staticClass:"iconfont icon-xiangshangzhanhang"})])])],1),_c('el-dialog',{attrs:{"width":"40%","show-close":false,"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('div',{staticClass:"t_box"},[_c('div',{staticClass:"t_tit"},[_vm._v("CONTACT US")]),_c('el-form',{ref:"dynamicValidateForm",staticClass:"yure",attrs:{"model":_vm.dynamicValidateForm}},[_c('div',{staticStyle:{"width":"100%"}},[_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"uname","rules":[
              {
                required: true,
                message: 'Please enter a name',
                trigger: 'blur',
              },
            ]}},[_c('div',{staticClass:"i_put"},[_c('div',{staticClass:"i_name"},[_vm._v("NAME")]),_c('el-input',{staticClass:"nsmes",attrs:{"placeholder":""},model:{value:(_vm.dynamicValidateForm.uname),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "uname", $$v)},expression:"dynamicValidateForm.uname"}})],1)]),_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"email","rules":[
              {
                required: true,
                message: 'Please enter your email address',
                trigger: 'blur',
              },
              {
                type: 'email',
                message: 'Please enter the correct email address',
                trigger: ['blur', 'change'],
              },
            ]}},[_c('div',{staticClass:"i_put"},[_c('div',{staticClass:"i_name"},[_vm._v("EMALL ADDRESS")]),_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.dynamicValidateForm.email),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "email", $$v)},expression:"dynamicValidateForm.email"}})],1)]),_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"mobile","rules":[
              {
                required: true,
                message: 'Please enter PHONE NUMBER',
                trigger: 'blur',
              },
            ]}},[_c('div',{staticClass:"i_put"},[_c('div',{staticClass:"i_name"},[_vm._v("PHONE NUMBER")]),_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.dynamicValidateForm.mobile),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "mobile", $$v)},expression:"dynamicValidateForm.mobile"}})],1)])],1),_c('el-form-item',{attrs:{"span":6,"prop":"remark","rules":[
            {
              required: true,
              message: 'Please enter Message',
              trigger: 'blur',
            },
          ]}},[_c('div',{staticClass:"i_put"},[_c('div',{staticClass:"i_name"},[_vm._v("MESSAGE")]),_c('el-input',{attrs:{"autosize":{ minRows: 3, maxRows: 9 },"type":"textarea","placeholder":""},model:{value:(_vm.dynamicValidateForm.remark),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "remark", $$v)},expression:"dynamicValidateForm.remark"}})],1)])],1),_c('div',{staticClass:"flex"},[_c('el-button',{staticClass:"btn1",on:{"click":function($event){return _vm.rest('dynamicValidateForm')}}},[_vm._v("RESET")]),_c('el-button',{staticClass:"btn2",on:{"click":function($event){return _vm.oemform('dynamicValidateForm')}}},[_vm._v("SEND")])],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit"},[_c('div',{staticClass:"iconfont icon-dianhua1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit"},[_c('div',{staticClass:"iconfont icon-whatsapp"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit"},[_c('div',{staticClass:"iconfont icon-youxiang"})])
}]

export { render, staticRenderFns }