import config from "../config/index.js";

class Utils {
  checkUrl() {
    if (process.env.NODE_ENV == "development") {
      return config.devBase;
    } else {
      return config.prodBase;
    }
  }
}

export default new Utils();
