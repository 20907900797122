<template>
  <div style="background: #f5f5f5">
    <div class="fot">
      <div class="" v-if="flags == 1">
        <div class="contacts">CONTACT US</div>
        <contants></contants>
        <!-- <div>
          <div class="dos">Do you want to know more about us</div>
          <div class="Xianqi">Anhui Xianqi</div>
        </div> -->
        <!-- <div v-if="oems == 0">
          <div class="i_more" @click="dialogFormVisible = true">
            <b>Contact Us </b>
          </div>
        </div> -->
      </div>
      <div class="qwa">
        <div class="logo">
          <img class="qlogos" :src="logos" alt="" />
        </div>
        <div class="left_one">
          <div class="logos" @click="byna(0)">Come Into Us</div>
          <div class="tits" @click="byna(1)">Company profile</div>
          <div class="tits" @click="byna(2)">Enterprise reality</div>
          <div class="tits" @click="byna(3)">R&D and Production</div>
        </div>
        <div class="left_two">
          <div class="logos">Self Operated Products</div>
          <div
            class="tits"
            @click="canans(it)"
            v-for="(it, i) in arrays"
            :key="i"
          >
            {{ it.name }}
          </div>
        </div>
        <div class="left_thress">
          <div class="logos">News And Information</div>
          <div class="tits" @click="opens(143)">Industry dynamics</div>
          <div class="tits" @click="opens(127)">Xianqi Dynamics</div>
        </div>
      </div>
      <div class="Reserved">© Copyright2024, All Rights Reserved</div>

      <el-dialog
        width="40%"
        :show-close="false"
        :visible.sync="dialogFormVisible"
      >
        <div class="t_box">
          <div class="t_tit">CONTACT US</div>
          <div class="i_put">
            <div class="i_name">NAME</div>
            <el-input class="nsmes" v-model="uname" placeholder=""></el-input>
          </div>
          <div class="i_put">
            <div class="i_name">EMALL ADDRESS</div>
            <el-input v-model="email" placeholder=""></el-input>
          </div>
          <div class="i_put">
            <div class="i_name">PHONE NUMBER</div>
            <el-input v-model="mobile" placeholder=""></el-input>
          </div>
          <div class="i_put">
            <div class="i_name">MESSAGE</div>
            <el-input
              :autosize="{ minRows: 3, maxRows: 9 }"
              type="textarea"
              v-model="remark"
              placeholder=""
            ></el-input>
          </div>
          <div class="flex">
            <el-button class="btn1" @click="rest">RESET</el-button>
            <el-button class="btn2" @click="send">SEND</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <side :info="info"></side>
  </div>
</template>

<script>
import side from "@/components/side.vue";
import contants from "@/components/contant.vue";
export default {
  name: "XiaofangFooter",
  components: {
    side,
    contants,
  },
  data() {
    return {
      dialogFormVisible: false,
      input: "",
      remark: "",
      uname: "",
      mobile: "",
      email: "",
      arrays: [],
      logos: "",
      oems: 0,
      flags: 0,
      info: "",
    };
  },
  mounted() {},
  created() {
    if (this.$route.path == "/" || this.$route.path == "/pro_det") {
      this.flags = 1;
    }
    if (this.$route.path == "/oem") {
      this.oems = 1;
    } else {
      this.oems = 0;
    }
    this.catList();
    this.alogos();
    this.wcontents();
  },

  methods: {
    async wcontents() {
      let res = await this.$http.index.content();
      if (res.code == 1) {
        this.info = res.data;
      }
    },
    async alogos() {
      let res = await this.$http.index.logo({});
      if (res.code == 1) {
        this.logos = this.$imgurl + res.data.logo_img;
      }
    },
    opens(id) {
      this.$router.push({ path: "/new", query: { id: id } });
    },
    canans(it) {
      this.$router.push({ path: "/pro_list", query: { id: it.id } });
    },
    byna(i) {
      this.$router.push({ path: "/about", query: { id: i } });
    },
    async catList() {
      let res = await this.$http.index.catList();
      if (res.code == 1) {
        this.arrays = res.data;
      }
    },
    rest() {
      this.remark = "";
      this.uname = "";
      this.mobile = "";
      this.email = "";
    },
    async send() {
      let res = await this.$http.index.diyform({
        remark: this.remark,
        uname: this.uname,
        mobile: this.mobile,
        email: this.email,
      });
      if (res.code == 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.remark = "";
        this.uname = "";
        this.mobile = "";
        this.email = "";
        this.$router.push({ path: "/thanks" });
      }
    },
  },
};
</script>
<style scoped lang="less">
.contacts {
  font-size: 30px;
  font-family: "Variable";
  text-align: center;
  margin-bottom: 30px;
}
.qlogos {
  width: 100px;
}
.btn1 {
  flex: 1;
  border: none !important;
  height: 50px;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #1ba8c9;
  font-family: "Variable";
}
.nsmes {
  width: 60%;
}
.btn2 {
  font-family: "Variable";
  height: 50px;
  flex: 1;
  margin-left: 28px;
  background: #1ba8c9;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
/deep/.el-button {
  padding: 0 !important;
}
.btn2:hover {
  background: #1ba8c9;
  color: #ffffff;
}
/deep/ .el-input__inner {
  background: #eff0f4 !important;
  border: none !important;
  height: 50px !important;
}
/deep/ .el-textarea__inner {
  background: #eff0f4 !important;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog {
  width: 610px !important;
}
.t_box {
  padding: 72px 58px 49px;
  background: #fff;
  .t_tit {
    font-family: "Variable";
    font-weight: 600;
    font-size: 26px;
    color: #333333;
    text-align: center;
    margin-bottom: 71px;
  }
  .i_put {
    margin-bottom: 25px;
    .i_name {
      font-family: Source Sans Variable;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      // line-height: 59px;
      opacity: 0.75;
      margin-bottom: 10px;
    }
  }
}
.Reserved {
  font-weight: 300;
  font-size: 21px;
  color: #333333;
  line-height: 58px;
  text-align: right;
}
.tits {
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  line-height: 46px;
  opacity: 0.75;
  cursor: pointer;
}
.logos {
  font-family: "Variable";
  font-weight: 600;
  font-size: 22px;
  color: #333333;
  margin-bottom: 39px;
}
.logo {
  font-family: "Variable";
  font-weight: bold;
  font-size: 36px;
  color: #333333;
}
.left_one {
  margin-left: 150px;
}
.left_two {
  margin-left: 100px;
}
.left_thress {
  margin-left: 130px;
}
.qwa {
  padding: 90px 60px 60px;
  border-bottom: 1px solid #969696;
  display: flex;
}
.fot {
  width: 1197px;

  margin: 0 auto;
  padding: 146px 0 61px;
  .qes {
    padding: 0px 0px 102px;
    border-bottom: 1px solid #969696;
    .dos {
      font-family: "Variable";
      font-weight: 400;
      font-size: 30px;
      color: #333333;
    }
    .Xianqi {
      font-family: "Variable";
      font-weight: 400;
      font-size: 30px;
      color: #1ba8c9;
      margin-top: 16px;
    }
  }
}
.i_more {
  width: 180px;
  height: 58px;
  text-align: center;
  line-height: 58px;
  box-sizing: border-box;
  /* background: none; */
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  // border: 1px solid #1ba8c9;
  transition: 0.5s ease;
  background: #1ba8c9;
  cursor: pointer;
}

.i_more b {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 2;
  transition: 0.5s ease;
  font-family: "Variable";
}

// .i_more b iconify-icon {
//   margin-left: 6px;
//   font-size: 0.2rem;
// }

.i_more::before {
  content: "";
  width: 0;
  height: 1000%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #fff;
  transition: 0.5s ease;
  display: block;
  z-index: 1;
}

.i_more:hover::before {
  width: 180%;
}

.i_more:hover b {
  color: #015197;
}

a {
  text-decoration: none;
}
</style>
