<template>
  <div class="">
    <el-form
      class="yure"
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
    >
      <div class="flex" style="width: 100%">
        <!-- 名称 -->
        <el-form-item
          class="ipt"
          prop="name"
          :rules="[
            {
              required: true,
              message: 'Please enter a name',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="dynamicValidateForm.name"
            placeholder="Name"
          ></el-input>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item
          class="ipt"
          prop="email"
          :rules="[
            {
              required: true,
              message: 'Please enter your email address',
              trigger: 'blur',
            },
            {
              type: 'email',
              message: 'Please enter the correct email address',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            placeholder="Email"
            v-model="dynamicValidateForm.email"
          ></el-input>
        </el-form-item>

        <!-- whatsApp -->
        <el-form-item
          class="ipt"
          prop="whatsApp"
          :rules="[
            {
              required: true,
              message: 'Please enter WhatsApp',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="dynamicValidateForm.whatsApp"
            placeholder="WhatsApp"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item
        :span="6"
        prop="textarea2"
        :rules="[
          {
            required: true,
            message: 'Please enter Message',
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 7 }"
          placeholder="Message"
          v-model="dynamicValidateForm.textarea2"
        >
        </el-input>
      </el-form-item>
      <div class="flexC bots">
        <div class="i_more" @click="oemform('dynamicValidateForm')">
          <b>SUBMIT </b>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dynamicValidateForm: {
        name: "",
        email: "",
        whatsApp: "",
        textarea2: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    async oemform(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.adds();
        } else {
          return false;
        }
      });
    },

    async adds() {
      let formName = this.dynamicValidateForm;
      let res = await this.$http.index.diyform({
        remark: formName.textarea2,
        uname: formName.name,
        mobile: formName.whatsApp,
        email: formName.email,
      });
      if (res.code == 1) {
        this.$message({
          message: "success",
          type: "success",
        });
        this.dynamicValidateForm.textarea2 = "";
        this.dynamicValidateForm.name = "";
        this.dynamicValidateForm.whatsApp = "";
        this.dynamicValidateForm.email = "";
        this.$router.push({ path: "/thanks" });
      }
    },
  },
};
</script>

<style scoped lang="less">
.ipt {
  margin-right: 25px;
  border: none !important;
  flex: 1;
  &:last-child {
    margin-right: 0px;
  }
}
.bots {
  margin-top: 23px;
}
.i_more {
  width: 274px;
  height: 41px;
  text-align: center;
  line-height: 41px;
  box-sizing: border-box;
  /* background: none; */
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  // border: 1px solid #1ba8c9;
  transition: 0.5s ease;
  background: #1ba8c9;
  cursor: pointer;
}

.i_more b {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 2;
  transition: 0.5s ease;
  font-family: "Variable";
}

.i_more::before {
  content: "";
  width: 0;
  height: 1000%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #fff;
  transition: 0.5s ease;
  display: block;
  z-index: 1;
}

.i_more:hover::before {
  width: 180%;
}

.i_more:hover b {
  color: #015197;
}
</style>
