import axios from "axios";
import utils from "../utils";
// import { Toast } from "vant";
// import { Notification } from "element-ui";
// import { setTimeout } from "core-js";

class HTTP {
  constructor() {
    this.request();
    this.response();
  }

  async get(params) {
    this.SetBaseUrl();
    return await axios.get(params.url, {
      params: params.data,
      headers: Object.assign(params.header, {
        token: localStorage.getItem("access_token"),
        // Authorization: localStorage.getItem("access_token"),
        "X-Requested-With": "XMLHttpRequest ", //自定义请求头信息
        //"Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      }),
    });
  }

  async post(params) {
    this.SetBaseUrl();
    return await axios.post(params.url, params.data, {
      headers: Object.assign(params.header, {
        token: localStorage.getItem("access_token"),
        // Authorization: localStorage.getItem("access_token"),
        "X-Requested-With": "XMLHttpRequest ", //自定义请求头信息
        //"Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      }),
    });
  }

  /**
   * 请求拦截器
   */
  request() {
    axios.interceptors.request.use(
      function (config) {
        // 在发送请求之前做些什么
        return config;
      },
      function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
      }
    );
  }

  /**
   * 添加响应拦截器
   */
  response() {
    axios.interceptors.response.use(
      function (response) {
        // 对响应数据做点什么
        switch (response.data.code) {
          case 0:
            return response.data;
          case 1:
            return response.data;
          // case 70002:
          //   return response.data;
          // case 401:
          //   console.log(response, "8888");
          //   this.$router.push("/login");
          //   return response.data;

          // case 20004:
          //   console.log(response);
          //   Toast.fail(response.data.msg);
          //   setTimeout(() => {
          //     return (location.href = "#/login");
          //   }, 2000);
          //   break;
          // case 20003:
          //   Toast.fail("请登陆后访问");
          //   setTimeout(() => {
          //     return (location.href = "#/login");
          //   }, 2000);
          //   break;
          // case 40011:
          //   Toast.fail("请登陆后访问");
          //   return response.data;
          // case 40009:
          //   Toast.fail("请登陆后访问");
          //   return response.data;
          // case 40012:
          //   Toast.fail("请登陆后访问");
          //   return response.data;
          // case 60001:
          //   return response.data;

          default:
          // Toast.fail(response.data.msg);
          // throw new Error(response.data.msg);
        }
      },
      function (error) {
        // 对响应错误做点什么
        // Toast.fail("请求失败");
        // setTimeout(() => {
        //   location.href = "#/login";
        // }, 800);

        return Promise.reject(error);
      }
    );
  }

  SetBaseUrl() {
    axios.defaults.baseURL = utils.checkUrl();
  }
}

export default new HTTP();
