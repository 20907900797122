import Service from "../service";

export default {
  devBase: "https://api.cichbiooem.com/api/",
  prodBase: "https://api.cichbiooem.com/api/",
  imgurl: "https://api.cichbiooem.com/",

  ApiList: {
    logo: "common/logo",
    banner: "index/banner",
    product: "index/product", //
    aboutus: "index/aboutus", //关于我们
    news: "common/news", //公司动态
    rdProduct: "index/rdProduct", //自营产品
    catList: "common/catList", //产品列表
    newsInfo: "index/newsInfo", //
    content: "index/content", //联系我们
    diyform: "index/diyform", //留言
    oemform: "index/oemform", //oem
    productInfo: "index/productInfo", //产品详情
    productList: "index/productList",
  },
  // VuePlugs: [Vant],
  NotVuePlugs: [
    {
      n: "$http",
      v: Service,
    },
  ],
};
