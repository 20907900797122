import http from "../http";
import config from "../config";
class IndexService {
  async banner(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.banner,
      data: data,
      header: header,
    });
  }
  async product(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.product,
      data: data,
      header: header,
    });
  }
  async aboutus(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.aboutus,
      data: data,
      header: header,
    });
  }
  async news(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.news,
      data: data,
      header: header,
    });
  }
  async rdProduct(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.rdProduct,
      data: data,
      header: header,
    });
  }
  async catList(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.catList,
      data: data,
      header: header,
    });
  }
  async newsInfo(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.newsInfo,
      data: data,
      header: header,
    });
  }
  async content(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.content,
      data: data,
      header: header,
    });
  }
  async diyform(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.diyform,
      data: data,
      header: header,
    });
  }
  async productInfo(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.productInfo,
      data: data,
      header: header,
    });
  }
  async logo(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.logo,
      data: data,
      header: header,
    });
  }
  async productList(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.productList,
      data: data,
      header: header,
    });
  }
  async oemform(data = {}, header = {}) {
    return await http.post({
      url: config.ApiList.oemform,
      data: data,
      header: header,
    });
  }
}

export default new IndexService();
