<template lang="pug">
#app
  keep-alive
    router-view(v-if="$route.meta.keepAlive", :key="$route.fullPath")
  router-view(v-if="!$route.meta.keepAlive", :key="$route.fullPath")
</template>
<script>
export default {
  data() {
    return {};
  },
  mouted() {},

  methods: {},
};
</script>
<style lang="less">
* {
  ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
}

.contentbox {
  margin-top: -155px !important;
}
* {
  margin: 0;
  padding: 0;
}
.contant {
  width: 1206px;
  margin: 0 auto;
}
.flex {
  display: flex;
  align-items: center;
}
.flexB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexC {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexCF {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.win72 {
  width: 75%;
  margin: 0 auto;
}
.marTop30 {
  margin-top: 30px;
}
.mar15 {
  margin-top: 15px;
}
.marBoot30 {
  margin-bottom: 30px;
}
.pad10 {
  padding-top: 10px;
}
.mar-left30 {
  margin-left: 30px;
}
.scroll-container {
  overflow: hidden !important; /* 隐藏滚动条 */
  scrollbar-width: none !important; /* 对于 Firefox 隐藏滚动条 */
}

.scroll-container::-webkit-scrollbar {
  display: none !important; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
}
</style>
