import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "Layout" */ "@/views/Layout"),
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          keepAlive: false,
        },
        component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
      },
    ],
  },
  {
    path: "/beacon",
    name: "beacon",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/beacon"),
  },
  {
    path: "/oem",
    name: "oem",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/oem"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/about"),
  },
  {
    path: "/product",
    name: "product",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/product"),
  },
  {
    path: "/callme",
    name: "callme",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/callme"),
  },
  {
    path: "/new",
    name: "new",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/new"),
  },
  {
    path: "/new_det",
    name: "new_det",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/new_det"),
  },
  {
    path: "/pro_det",
    name: "pro_det",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/pro_det"),
  },
  {
    path: "/pro_list",
    name: "pro_list",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/pro_list"),
  },
  {
    path: "/thanks",
    name: "thanks",
    meta: {
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/thanks"),
  },
];
// 针对ElementUI导航栏中重复导航报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
