import "amfe-flexible";

// 在 main.js 中引入
import "@/assets/fontFamily/font.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import config from "./config/index.js";
import Http from "./service/index.js";

var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

Vue.prototype.$http = Http;
import ElementUI from "element-ui";
import headers from "./components/header.vue";
import Lheaders from "./components/Lheader.vue";
import footers from "./components/footer.vue";
import "@/utils/rem.js";
import "element-ui/lib/theme-chalk/index.css";
Vue.component("headers", headers);
Vue.component("Lheaders", Lheaders);
Vue.prototype.$imgurl = config.imgurl;
Vue.component("footers", footers);
Vue.use(ElementUI);
// // 隐藏 右侧浏览器 滚动条
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
