var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('el-form',{ref:"dynamicValidateForm",staticClass:"yure",attrs:{"model":_vm.dynamicValidateForm}},[_c('div',{staticClass:"flex",staticStyle:{"width":"100%"}},[_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"name","rules":[
          {
            required: true,
            message: 'Please enter a name',
            trigger: 'blur',
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.dynamicValidateForm.name),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "name", $$v)},expression:"dynamicValidateForm.name"}})],1),_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"email","rules":[
          {
            required: true,
            message: 'Please enter your email address',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please enter the correct email address',
            trigger: ['blur', 'change'],
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.dynamicValidateForm.email),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "email", $$v)},expression:"dynamicValidateForm.email"}})],1),_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"whatsApp","rules":[
          {
            required: true,
            message: 'Please enter WhatsApp',
            trigger: 'blur',
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"WhatsApp"},model:{value:(_vm.dynamicValidateForm.whatsApp),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "whatsApp", $$v)},expression:"dynamicValidateForm.whatsApp"}})],1)],1),_c('el-form-item',{attrs:{"span":6,"prop":"textarea2","rules":[
        {
          required: true,
          message: 'Please enter Message',
          trigger: 'blur',
        },
      ]}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 4, maxRows: 7 },"placeholder":"Message"},model:{value:(_vm.dynamicValidateForm.textarea2),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "textarea2", $$v)},expression:"dynamicValidateForm.textarea2"}})],1),_c('div',{staticClass:"flexC bots"},[_c('div',{staticClass:"i_more",on:{"click":function($event){return _vm.oemform('dynamicValidateForm')}}},[_c('b',[_vm._v("SUBMIT ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }