<!-- 模板 -->
<template>
  <div class="">
    <div class="box">
      <div class="lists">
        <div class="tit">
          <div class="iconfont icon-dianhua1"></div>
        </div>
        <div class="num">
          <a :href="'tel:' + info.telephone" target="_blank">{{
            info.telephone
          }}</a>
        </div>
      </div>
      <div class="lists">
        <div class="tit">
          <div class="iconfont icon-whatsapp"></div>
        </div>
        <div class="num">
          <a :href="'https://wa.me/' + info.mobile" target="_blank">{{
            info.mobile
          }}</a>
        </div>
      </div>
      <div class="lists" @click="dialogFormVisible = true">
        <div class="tit">
          <div class="iconfont icon-youxiang"></div>
        </div>
        <!-- <div class="imgs">
          <img src="../assets/img/3.jpg" alt="" />
        </div> -->
      </div>
      <el-backtop>
        <div class="tit">
          <div class="iconfont icon-xiangshangzhanhang"></div>
        </div>
      </el-backtop>
    </div>

    <el-dialog
      width="40%"
      :show-close="false"
      :visible.sync="dialogFormVisible"
    >
      <div class="t_box">
        <div class="t_tit">CONTACT US</div>

        <el-form
          class="yure"
          :model="dynamicValidateForm"
          ref="dynamicValidateForm"
        >
          <div class="" style="width: 100%">
            <!-- 名称 -->
            <el-form-item
              class="ipt"
              prop="uname"
              :rules="[
                {
                  required: true,
                  message: 'Please enter a name',
                  trigger: 'blur',
                },
              ]"
            >
              <div class="i_put">
                <div class="i_name">NAME</div>
                <el-input
                  class="nsmes"
                  v-model="dynamicValidateForm.uname"
                  placeholder=""
                ></el-input>
              </div>
            </el-form-item>
            <!-- 邮箱 -->
            <el-form-item
              class="ipt"
              prop="email"
              :rules="[
                {
                  required: true,
                  message: 'Please enter your email address',
                  trigger: 'blur',
                },
                {
                  type: 'email',
                  message: 'Please enter the correct email address',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <div class="i_put">
                <div class="i_name">EMALL ADDRESS</div>
                <el-input
                  v-model="dynamicValidateForm.email"
                  placeholder=""
                ></el-input>
              </div>
            </el-form-item>

            <!-- whatsApp -->
            <el-form-item
              class="ipt"
              prop="mobile"
              :rules="[
                {
                  required: true,
                  message: 'Please enter PHONE NUMBER',
                  trigger: 'blur',
                },
              ]"
            >
              <div class="i_put">
                <div class="i_name">PHONE NUMBER</div>
                <el-input
                  v-model="dynamicValidateForm.mobile"
                  placeholder=""
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <el-form-item
            :span="6"
            prop="remark"
            :rules="[
              {
                required: true,
                message: 'Please enter Message',
                trigger: 'blur',
              },
            ]"
          >
            <div class="i_put">
              <div class="i_name">MESSAGE</div>
              <el-input
                :autosize="{ minRows: 3, maxRows: 9 }"
                type="textarea"
                v-model="dynamicValidateForm.remark"
                placeholder=""
              ></el-input>
            </div>
          </el-form-item>
        </el-form>

        <div class="flex">
          <el-button class="btn1" @click="rest('dynamicValidateForm')"
            >RESET</el-button
          >
          <el-button class="btn2" @click="oemform('dynamicValidateForm')"
            >SEND</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      // info: "",
      dynamicValidateForm: {
        uname: "",
        email: "",
        mobile: "",
        remark: "",
      },
      dialogFormVisible: false,
      current: null,
    };
  },

  props: {
    info: {
      type: [Object, String],
      default: "",
    },
  },

  computed: {},
  filters: {},
  methods: {
    rest(formName) {
      this.$refs[formName].resetFields();
    },

    async oemform(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.adds();
        } else {
         
          return false;
        }
      });
    },

    async adds() {
      let formName = this.dynamicValidateForm;
      let res = await this.$http.index.diyform({
        remark: formName.remark,
        uname: formName.uname,
        mobile: formName.mobile,
        email: formName.email,
      });
      if (res.code == 1) {
        this.$message({
          message: "success",
          type: "success",
        });
        this.dialogFormVisible = false;
        this.dynamicValidateForm.remark = "";
        this.dynamicValidateForm.uname = "";
        this.dynamicValidateForm.mobile = "";
        this.dynamicValidateForm.email = "";
        this.$router.push({ path: "/thanks" });
      }
    },
    enter(i) {
     
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
  color: #fff;
}
.tops {
  width: 50px;
  height: 50px;
}

.btn1 {
  flex: 1;
  border: none !important;
  height: 50px;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #1ba8c9;
  font-family: "Variable";
}
.nsmes {
  width: 60%;
}
.btn2 {
  font-family: "Variable";
  height: 50px;
  flex: 1;
  margin-left: 28px;
  background: #1ba8c9;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
/deep/.el-button {
  padding: 0 !important;
}
.btn2:hover {
  background: #1ba8c9;
  color: #ffffff;
}
/deep/ .el-input__inner {
  background: #eff0f4 !important;
  // border: none !important;
  height: 50px !important;
}
/deep/ .el-textarea__inner {
  background: #eff0f4 !important;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog {
  width: 610px !important;
}

.t_box {
  padding: 30px 58px 30px;
  background: #fff;
  .t_tit {
    font-family: "Variable";
    font-weight: 600;
    font-size: 26px;
    color: #333333;
    text-align: center;
    margin-bottom: 40px;
  }
  .i_put {
    // margin-bottom: 25px;
    .i_name {
      font-family: Source Sans Variable;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      // line-height: 59px;
      opacity: 0.75;
      margin-bottom: 10px;
    }
  }
}

.el-backtop {
  position: relative !important;
  // top: 40% !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 50px;
  height: 50px;
  background: #015197 !important;
  border-radius: 10px !important;
  .iconfont {
    color: #fff !important;
    font-size: 16px;
  }
}

@font-face {
  font-family: "iconfont"; /* Project id 4344213 */
  src: url("//at.alicdn.com/t/c/font_4344213_ct0zilf25oo.woff2?t=1724125095024")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_4344213_ct0zilf25oo.woff?t=1724125095024")
      format("woff"),
    url("//at.alicdn.com/t/c/font_4344213_ct0zilf25oo.ttf?t=1724125095024")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dianhua1:before {
  content: "\e614";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-xiangshangzhanhang:before {
  content: "\ec0b";
}

.icon-whatsapp:before {
  content: "\e97b";
}

//@import url(); 引入公共css类
.box {
  width: 50px;
  padding: 0px 0.5px;
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 10000;

  .lists {
    position: relative;
    height: 50px;
    width: 50px;
    margin-bottom: 15px;
    &:hover .num {
      opacity: 1;
      z-index: -1;
      transform: translateX(-25px);
    }
    &:hover .imgs {
      opacity: 1;
      z-index: 1;
      transform: translateX(-25px);
    }

    .tit {
      width: 50px;
      height: 50px;
      background-color: #555555;
      border-radius: 5px;
      // position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 2;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .iconfont {
        font-size: 30px;
        z-index: 1;
      }
    }
    .num {
      position: absolute;
      transition: 0.5s;
      width: 250px;
      transform: translateX(30px);
      top: 0;
      right: 0;
      height: 50px;
      background-color: #008edc;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      z-index: -1;
      color: #fff;
      border-radius: 6px 0 0 6px;
      font-size: 14px;
    }
    .imgs {
      position: absolute;
      transform: translateX(-30px);
      transition: 0.5s;
      top: -80%;
      left: 0%;
      opacity: 0;
      margin-left: -150px;
      img {
        width: 140px;
        height: 140px;
      }
    }
  }
}
</style>
